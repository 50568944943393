import HeaderMain from '~/themes/base/components/shop/HeaderMain'

export default {
  mixins: [HeaderMain],
  computed: {
    phoneLink() {
      const phone = this.$store.state.globals.contact?.phone?.replace(/ /g, '')
      return phone && `tel:${phone}`
    },
    showCurrencySwitcher() {
      return (
        this.$themeSettings.components.CurrencySwitcher.enable &&
        this.$themeSettings.components.HeaderMain.showCurrencySwitcher
      )
    },
  },
}
